
import Form from "./Form.vue";
import { useStore } from 'vuex';
import { defineComponent, ref,onMounted,computed, toRefs, reactive} from 'vue'


export default defineComponent({
  components: {
    Form
  },
  setup(){
     const form = reactive({
      id: "",
      product_category_name: "",
      threshold_value: "",
    });
    const form_action = ref('save')
    const visible = ref(false);
    const store = useStore();

   const fields = ref([
      {
        title: "Product Category Name",
        dataIndex: "product_category_name",
      },
      {
        title: "Threshold Value",
        dataIndex: "threshold_value",
      },
      {
        title: "Positive C3",
        dataIndex: "threshold_value",
        align: 'center',
        slots: { customRender: "positive" }
      },
      {
        title: "Marginal C3",
        dataIndex: "threshold_value",
        align: 'center',
        slots: { customRender: "marginal" }
      },
      {
        title: "Negative C3",
        dataIndex: "threshold_value",
        align: 'center',
        slots: { customRender: "negative" }
      },
      // {
      //   title: "Is Active",
      //   dataIndex: "is_active",
      //   align: 'center',
      //   slots: { customRender: "is_active" }
      // },
      {
        title: "Action",
        dataIndex: "action",
        slots: { customRender: "action" },
        align: 'center'
      },
    ]);

    const index = (payload={page: 1}) => {
      store.dispatch("ProductCategory/index", payload);
    };
    
    const editRecord = (record: any) => {
      form_action.value = 'update'
      const { id, product_category_name, threshold_value} = toRefs(form);
      id.value = record.id;
      product_category_name.value = record.product_category_name;
      threshold_value.value = record.threshold_value;
      visible.value = true;
    };

    const createRecord = () => {
      form_action.value = 'save'
      const { product_category_name, threshold_value  } = toRefs(form);
      product_category_name.value = '';
      threshold_value.value = '';
      visible.value = true;
    };

    const deleteRecord = (record: any) => {
      store.dispatch("ProductCategory/destroy", record.id);
    };

    const handleSaving = (record: any) => {
      form_action.value == 'save' ? storeRecord(record) : updateRecord(record);
    }

    const storeRecord = (payload = {}) => {
      store
        .dispatch("ProductCategory/store", payload)
        .then(() => (visible.value = false));
    };

    const updateRecord = (payload = {}) => {
      store
        .dispatch("ProductCategory/update", payload)
        .then(() => (visible.value = false));
    };

    const filterRecord = (payload: any) => {
      index(payload);
    }

    const items = computed(() => store.getters["ProductCategory/items"]);
    const loading = computed(() => store.getters["ProductCategory/status"]);

    onMounted(index);


    return {
      loading,
      items,
      fields,
      visible,
      form,
      editRecord,
      deleteRecord,
      storeRecord,
      createRecord,
      updateRecord,
      handleSaving,
      filterRecord
    };
  },
});
